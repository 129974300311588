* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-size: 62.5%; */
  font-family: "Nunito", sans-serif;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  background-color: $main-color;
  position: relative;
}
